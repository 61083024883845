import ContentSection from "../content-section"
import React, { FC } from "react"
import styled from "styled-components"
import { clamp } from "../../styles/helpers"
import { OneByOneGrid } from "../layout/grid"
import { useIntl } from "gatsby-plugin-intl"
import { VideoWrapper } from "../video-wrapper"
import YoutubeVideo from "../youtubeVideo"

const OfferingSection: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <ContentSection id={"offering"}>
      <OneByOneGrid>
        <Offerings>
          <Claim>{formatMessage({ id: `offering.claim1` })}</Claim> <br />{" "}
          <br />
          {formatMessage({ id: `offering.attribute1` })} <br />
          {formatMessage({ id: `offering.attribute2` })} <br />
          {formatMessage({ id: `offering.attribute3` })} <br /> <br />
          <Claim>{formatMessage({ id: `offering.claim2` })}</Claim> <br />
        </Offerings>
      </OneByOneGrid>
      <VideoWrapper>
        <YoutubeVideo
          youtubeVideoId="kyKiye2XIxs"
          videoTrackingId="delta-animated-story"
        />
      </VideoWrapper>
    </ContentSection>
  )
}

export default OfferingSection

const Offerings = styled.div`
  font-size: ${clamp(1.5, 2)};
  line-height: 1.2;
`

const Claim = styled.strong``
