import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { AppliedVariant } from "./types"
import testConfigs from "./tests"

type VariantContextType = {
  activeVariant: (testName: string) => string | undefined
}

const VariantContext = createContext<VariantContextType>({
  activeVariant: () => "",
})

export const VariantContextProvider: FC = ({ children }) => {
  const [activeVariants, setActiveVariants] = useState<AppliedVariant[]>([])

  // build test config
  useEffect(() => {
    testConfigs.forEach((testConfig) => {
      const _paq = (window as any)._paq || []

      const enhancedVariations = testConfig.variations.map((variation) => {
        return {
          ...variation,
          activate: (event: any) => {
            setActiveVariants([
              ...activeVariants,
              {
                testName: testConfig.name,
                variantName: variation.name,
              },
            ])
            variation.activate(event)
          },
        }
      })

      _paq.push([
        "AbTesting::create",
        {
          ...testConfig,
          variations: enhancedVariations,
        },
      ])
    })
  }, [])

  const activeVariant = (testName: string) => {
    const activeVariant = activeVariants.find(
      (activeVariant) => activeVariant.testName === testName
    )
    return activeVariant && activeVariant.variantName
  }

  return (
    <VariantContext.Provider value={{ activeVariant }}>
      {children}
    </VariantContext.Provider>
  )
}

export const useVariants = () => useContext(VariantContext)
