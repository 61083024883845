import React from "react"

import Page from "../components/page"
import SEO from "../components/seo"
import { VariantContextProvider } from "../context/variant-context"
import Hero from "../components/hero"
import ChallengesSection from "../components/content-sections/challenges-section"
import OfferingSection from "../components/content-sections/offering-section"
import ReferencesSection from "../components/content-sections/references-section"
import { LimitedWidth } from "../components/layout/limited-width"
import { ContactForm } from "../components/contact-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const IndexPage = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl()

  return (
    <VariantContextProvider>
      <Page>
        <SEO title="Let's make a difference" location={location} />
        <Hero />
        <LimitedWidth>
          <ChallengesSection />
          <OfferingSection />
          <ReferencesSection
            ids={["data-driven-experimentation", "10-day-cloud-onboarding"]}
          />
          <ContactForm title={formatMessage({ id: "section.contact.cta" })}>
            <p>
              <FormattedMessage id="section.contact.content" />
            </p>
          </ContactForm>
        </LimitedWidth>
      </Page>
    </VariantContextProvider>
  )
}

export default IndexPage
