import React, { FC } from "react"
import styled from "styled-components"

export const VideoWrapper: FC<{ aspectRatio?: number }> = ({
  children,
  aspectRatio = 16 / 9,
}) => {
  return <Wrapper aspectRatio={aspectRatio}>{children}</Wrapper>
}

const Wrapper = styled.div<{ aspectRatio: number }>`
  margin: 2rem 0;
  position: relative;
  padding-bottom: ${({ aspectRatio }) => 100 / aspectRatio}%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
