import React, { FC, useState } from "react"
import { getSrc } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Logo } from "./logo"
import Button from "./button"
import { clamp } from "../styles/helpers"
import { LimitedWidth } from "./layout/limited-width"
import Typist from "react-typist"
import { CustomScrollLink } from "./custom-scroll-link"
import media from "styled-media-query"
import { useIntl } from "gatsby-plugin-intl"

const Hero: FC = ({}) => {
  const { formatMessage } = useIntl()
  const defaultChallenges = [
    formatMessage({ id: `hero.challenge1` }),
    formatMessage({ id: `hero.challenge2` }),
    formatMessage({ id: `hero.challenge3` }),
    formatMessage({ id: `hero.challenge4` }),
  ]
  const [challenges, setChallenges] = useState<string[] | null>(
    defaultChallenges
  )

  const data = useStaticQuery(
    graphql`
      query {
        riverDeltaImg: file(relativePath: { eq: "river-delta.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              transformOptions: { fit: COVER, cropFocus: SOUTH }
            )
          }
        }
      }
    `
  )

  const imageSrc = getSrc(data.riverDeltaImg)

  const onTypingDone = () => {
    setChallenges(null)
    setChallenges(defaultChallenges)
  }

  return (
    <StyledHero $src={imageSrc}>
      <LimitedWidth>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Slogan>
          <div>
            {formatMessage({ id: `hero.intro` })}&nbsp;
            {challenges != null && (
              <StyledTypist onTypingDone={onTypingDone}>
                {challenges?.map((slogan) => (
                  <span key={slogan}>
                    {slogan}
                    <Typist.Delay ms={2500} />
                    <Typist.Backspace count={slogan.length} delay={500} />
                    <Typist.Delay ms={200} />
                  </span>
                ))}
              </StyledTypist>
            )}
          </div>
          {formatMessage({ id: `hero.slogan` })}
        </Slogan>
        <CustomScrollLink to={"challenges"} title={"Let's make a difference"}>
          <Button inverted={true}>{formatMessage({ id: `hero.cta` })}</Button>
        </CustomScrollLink>
      </LimitedWidth>
    </StyledHero>
  )
}

export default Hero

const LogoContainer = styled.div`
  width: clamp(50px, 5vw, 70px);
  margin: 0 auto;
`

const StyledHero = styled.div<{ $src: string }>`
  position: relative;
  overflow: hidden;
  min-height: 60vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: ${clamp(2, 8)};
  text-align: center;

  @media (orientation: portrait) {
    min-height: 65vh;
  }

  background-size: cover;
  background-position: center;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3)
    ),
    url(${({ $src }) => $src});
`

const Slogan = styled.h1`
  font-weight: normal;
  font-size: ${clamp(2, 2.5)};
`

const StyledTypist = styled(Typist)`
  display: inline;
  font-weight: bold;

  ${media.lessThan("large")`
    display: block;
  `}

  .Cursor {
    opacity: 1;
    font-weight: normal;
    animation: blink 1s linear infinite;
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`
