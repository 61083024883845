import { TestConfig } from "./types"

const testConfigs: TestConfig[] = [
  {
    name: "WeAreHiring-Banner", // you can also use '7' (ID of the experiment) to hide the name
    percentage: 100,
    includedTargets: [
      { attribute: "url", inverted: "0", type: "any", value: "" },
    ],
    excludedTargets: [
      {
        attribute: "path",
        type: "contains",
        value: "tracking-privacy-policy",
        inverted: "0",
      },
    ],
    endDateTime: "2020/10/26 23:59:59 UTC",
    variations: [
      {
        name: "original",
        activate: function (event) {
          // usually nothing needs to be done here
        },
      },
      {
        name: "with-banner", // you can also use '11' (ID of the variation) to hide the name
        activate: function (event) {
          // eg $('#btn').attr('style', 'color: ' + this.name + ';');
        },
      },
    ],
    trigger: function () {
      return true // here you can further customize which of your visitors will participate in this experiment
    },
  },
]

export default testConfigs
