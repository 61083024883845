import ContentSection from "../content-section"
import React, { FC } from "react"
import styled, { useTheme } from "styled-components"
import { clamp } from "../../styles/helpers"
import { UnstyledLink } from "../unstyled-link"
import { lighten } from "polished"
import {
  LearnMoreIndicator,
  LearnMoreIndicatorStyles,
} from "../learn-more-indicator"
import media from "styled-media-query"
import { useIntl } from "gatsby-plugin-intl"
import { ChallengeKind } from "../challenge"
import { boxShadowSoft } from "../../styles/theme"
import { ChallengeIcon } from "../challenge-icon"

const ChallengesSection: FC = () => {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  return (
    <ContentSection id={"challenges"} title={formatMessage({ id: `challenges.title` })}>
      <Grid>
        {(["validating", "scaling", "evolving"] as ChallengeKind[]).map(
          (kind) => (
            <ChallengeCard
              to={`/challenges/${kind}`}
              $bgColor={theme.semanticColor[`${kind}_bg`]}
              $fgColor={theme.semanticColor[`${kind}_fg`]}
            >
              <CardContent>
                {/*<IconWrapper>*/}
                {/*  <ChallengeIcon kind={kind} />*/}
                {/*</IconWrapper>*/}
                <ChallengeTitle>
                  {formatMessage({ id: `challenge.${kind}.title` })}
                </ChallengeTitle>
                <ChallengeDescription>
                  {formatMessage({
                    id: `challenge.${kind}.shortDescription`,
                  })}
                </ChallengeDescription>
                <PushBottom>
                  <LearnMoreIndicator />
                </PushBottom>
              </CardContent>
            </ChallengeCard>
          )
        )}
      </Grid>
    </ContentSection>
  )
}

export default ChallengesSection

const CardContent = styled.div`
  z-index: 2;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  ${media.lessThan("large")`
   grid-template-columns: 1fr;
  `}
`

const ChallengeCard = styled(UnstyledLink)<{
  $bgColor: string
  $fgColor: string
}>`
  padding: ${clamp(2, 3)} ${clamp(1, 3)};
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  color: black;
  transition: color 0.3s;
  border-radius: 5px;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    background: ${({ $bgColor }) => $bgColor};
    z-index: 1;
    height: 10px;
    transition: height 0.3s;
  }

  ${LearnMoreIndicatorStyles};
  ${boxShadowSoft}

  &:hover {
    color: ${({ $fgColor }) =>
      $fgColor} !important; // important required for now due to a bug in Chrome;

    //background: ${({ $bgColor }) => lighten(0.02, $bgColor)};
    &:after {
      height: 100%;
    }
  }
`

const ChallengeTitle = styled.h3`
  margin: 0;
  font-size: ${clamp(2, 2.5)};
  text-align: center;
`

const ChallengeDescription = styled.p`
  line-height: 1.2;
`

const PushBottom = styled.div`
  margin-top: auto;
`

const IconWrapper = styled.div`
  width: 200px;
  height: 50px;
  margin: 0 auto 1rem;
`
