import YouTube from "react-youtube"
import React, { FC } from "react"
import { trackEvent, TrackingEventAction, TrackingEventCategory, TrackingEventLabel } from "../analytics"

type Props = {
  youtubeVideoId: string
  videoTrackingId: string
}

const YoutubeVideo: FC<Props> = ({
  youtubeVideoId,
  videoTrackingId
}) => (
  <YouTube
    videoId={youtubeVideoId}
    onPlay={() =>
      trackEvent(
        TrackingEventAction.CLICK,
        TrackingEventCategory.VIDEO_INTERACTION,
        TrackingEventLabel.PLAY_VIDEO,
        videoTrackingId
      )
    }
    onEnd={() =>
      trackEvent(
        TrackingEventAction.WAITED_FOR,
        TrackingEventCategory.VIDEO_INTERACTION,
        TrackingEventLabel.FINISH_VIDEO,
        videoTrackingId
      )
    }
    />
)

export default YoutubeVideo
